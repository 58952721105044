<script lang="ts" setup>
interface Props {
  label: string
  validator: RegExp
  value: string
}
const props = defineProps<Props>()
const isValid = computed(() => {
  return props.validator.test(props.value)
})

const classes = computed(() => {
  if (props.value.length === 0) {
    return 'bg-gray/5 border-gray text-gray'
  }

  if (isValid.value) {
    return 'bg-primary/10 border-primary text-primary'
  }

  return 'bg-red/10 border-red text-red'
})
</script>

<template>
  <div
    :class="classes"
    class="flex items-center rounded-md border px-2 py-0.5 text-[10px]"
  >
    <Icon :name="isValid ? 'check' : 'close'" class="mt-0.5 h-3 w-3" filled />
    <span>{{ label }}</span>
  </div>
</template>
